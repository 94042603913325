import Icon from '@ant-design/icons'

const TickSvg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7345 4.49479C17.0135 4.76236 17.0228 5.20548 16.7552 5.48452L8.36519 14.2345C8.23317 14.3722 8.05068 14.4501 7.85993 14.4501C7.66918 14.4501 7.48669 14.3722 7.35467 14.2345L4.49473 11.2519C4.22716 10.9728 4.23647 10.5297 4.51552 10.2622C4.79456 9.99459 5.23768 10.0039 5.50525 10.2829L7.85993 12.7386L15.7447 4.51558C16.0123 4.23653 16.4554 4.22722 16.7345 4.49479Z"
      fill="white"
    />
  </svg>
)

const TickIcon = (props: any) => <Icon component={TickSvg} {...props} />

export default TickIcon
