import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const ProjectsIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 20, 20',
  active,
}: SvgIconType) => {
  return (
    <svg width="20" height="20" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      {active ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.99999 4.38425C2.8343 4.38425 2.69999 4.51857 2.69999 4.68425V15.3158C2.69999 15.4815 2.8343 15.6158 2.99999 15.6158H3.81346C3.81652 15.6158 3.81768 15.6153 3.81824 15.6151C3.81927 15.6147 3.82087 15.6137 3.82254 15.6121C3.82421 15.6104 3.82515 15.6088 3.82558 15.6078C3.82582 15.6072 3.8263 15.6061 3.8263 15.603C3.8263 15.4336 3.85677 15.2655 3.91626 15.1069L6.15386 9.13998C6.40268 8.47646 7.03698 8.03688 7.74562 8.03688H15.6158V6.36846C15.6158 6.20278 15.4815 6.06846 15.3158 6.06846H9.99315C9.54228 6.06846 9.10988 5.88936 8.79107 5.57055L7.69264 4.47212C7.63638 4.41586 7.56007 4.38425 7.48051 4.38425H2.99999ZM17.0158 8.08437V6.36846C17.0158 5.42958 16.2547 4.66846 15.3158 4.66846H9.99315C9.91358 4.66846 9.83728 4.63686 9.78102 4.5806L8.68259 3.48217C8.36378 3.16336 7.93138 2.98425 7.48051 2.98425H2.99999C2.0611 2.98425 1.29999 3.74537 1.29999 4.68425V15.3158C1.29999 16.2547 2.0611 17.0158 2.99999 17.0158H14.7529C15.4846 17.0158 16.1343 16.5476 16.3657 15.8534L18.2253 10.2745C18.548 9.30634 17.9532 8.31039 17.0158 8.08437ZM7.74562 9.43688C7.62057 9.43688 7.50863 9.51446 7.46472 9.63155L5.22712 15.5985C5.22658 15.5999 5.2263 15.6014 5.2263 15.603C5.2263 15.6073 5.22628 15.6116 5.22625 15.6158H14.7529C14.882 15.6158 14.9967 15.5332 15.0375 15.4107L16.8972 9.83175C16.9619 9.63749 16.8173 9.43688 16.6126 9.43688H7.74562Z"
            fill="#16181D"
          />
          <path
            d="M6.80667 9.39375L4.56907 15.3607C4.53906 15.4407 4.52368 15.5255 4.52368 15.611C4.52368 16.0047 4.84283 16.3238 5.23652 16.3238H14.7503C15.1807 16.3238 15.5629 16.0484 15.699 15.64L17.5586 10.0611C17.7745 9.41357 17.2925 8.74487 16.6099 8.74487H7.743C7.32615 8.74487 6.95303 9.00345 6.80667 9.39375Z"
            fill="#16181D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.74298 9.4448C7.61793 9.4448 7.506 9.52237 7.46209 9.63946L5.22449 15.6064C5.22395 15.6078 5.22367 15.6094 5.22367 15.6109C5.22367 15.614 5.22415 15.6151 5.22439 15.6157C5.22483 15.6167 5.22576 15.6183 5.22743 15.62C5.2291 15.6217 5.2307 15.6226 5.23173 15.623C5.23229 15.6233 5.23345 15.6237 5.23651 15.6237H14.7503C14.8794 15.6237 14.994 15.5411 15.0349 15.4186L16.8945 9.83967L17.5586 10.061L16.8945 9.83967C16.9593 9.64541 16.8147 9.4448 16.6099 9.4448H7.74298ZM6.15122 9.14789C6.40004 8.48437 7.03435 8.0448 7.74298 8.0448H16.6099C17.7703 8.0448 18.5896 9.18158 18.2227 10.2824L16.363 15.8613C16.1316 16.5555 15.482 17.0237 14.7503 17.0237H5.23651C4.45622 17.0237 3.82367 16.3912 3.82367 15.6109C3.82367 15.4415 3.85414 15.2735 3.91363 15.1148L6.15122 9.14789Z"
            fill="#16181D"
          />
        </>
      ) : (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.5987 4.79707C3.44288 4.79707 3.31657 4.92151 3.31657 5.07502V14.925C3.31657 15.0785 3.44288 15.2029 3.5987 15.2029H4.36369C4.36657 15.2029 4.36819 15.2023 4.36819 15.2023C4.36819 15.2023 4.37066 15.201 4.37223 15.1994C4.3738 15.1979 4.37509 15.1955 4.37509 15.1955C4.37509 15.1955 4.37577 15.1939 4.37577 15.191C4.37577 15.0341 4.40442 14.8784 4.46037 14.7314L6.56462 9.20316C6.79862 8.58843 7.39512 8.18117 8.06153 8.18117H15.4627V6.63541C15.4627 6.4819 15.3364 6.35746 15.1806 6.35746H10.1751C9.75113 6.35746 9.34449 6.19153 9.04468 5.89615L8.01171 4.87848C7.9588 4.82636 7.88704 4.79707 7.81222 4.79707H3.5987ZM16.7793 8.22517V6.63541C16.7793 5.76555 16.0635 5.06039 15.1806 5.06039H10.1751C10.1003 5.06039 10.0285 5.03111 9.97563 4.97898L8.94267 3.96131C8.64285 3.66594 8.23622 3.5 7.81222 3.5H3.5987C2.71576 3.5 2 4.20516 2 5.07502V14.925C2 15.7948 2.71576 16.5 3.5987 16.5H14.6512C15.3394 16.5 15.9503 16.0662 16.1679 15.423L17.9167 10.2543C18.2202 9.3573 17.6608 8.43456 16.7793 8.22517ZM8.06153 9.47824C7.94393 9.47824 7.83866 9.55011 7.79737 9.6586L5.69311 15.1868L5.69243 15.1896L5.69234 15.191C5.69234 15.195 5.69232 15.199 5.69229 15.2029H14.6512C14.7727 15.2029 14.8805 15.1264 14.9189 15.0129L16.6677 9.84408C16.7286 9.66411 16.5926 9.47824 16.4001 9.47824H8.06153Z"
            fill="black"
          />
        </>
      )}
    </svg>
  )
}

export default ProjectsIcon
