import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const AllocationsIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 20, 20',
  active,
}: SvgIconType) => {
  return (
    <svg width="20" height="20" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      {active ? (
        <>
          <path
            d="M7.02338 6.20827C8.71257 6.20827 10.0819 4.8543 10.0819 3.18409C10.0819 1.51388 8.71257 0.159912 7.02338 0.159912C5.33419 0.159912 3.96483 1.51388 3.96483 3.18409C3.96483 4.8543 5.33419 6.20827 7.02338 6.20827Z"
            fill="#16181D"
          />
          <path
            d="M11.2936 0.85294C11.4872 0.518297 11.9154 0.403943 12.25 0.597524C13.1406 1.11271 13.7424 2.07736 13.7424 3.18337C13.7424 4.28939 13.1406 5.25404 12.25 5.76922C11.9154 5.9628 11.4872 5.84845 11.2936 5.51381C11.1 5.17917 11.2143 4.75096 11.549 4.55738C12.0248 4.28214 12.3424 3.76943 12.3424 3.18337C12.3424 2.59732 12.0248 2.08461 11.549 1.80937C11.2143 1.61579 11.1 1.18758 11.2936 0.85294Z"
            fill="#16181D"
          />
          <path
            d="M0.860962 13.143C0.860962 10.7233 2.12097 7.96307 7.07526 7.96307C12.0295 7.96307 13.2896 10.7233 13.2896 13.143C13.2896 13.528 13.0059 13.8401 12.6559 13.8401H1.49458C1.14464 13.8401 0.860962 13.528 0.860962 13.143Z"
            fill="#16181D"
          />
          <path
            d="M17.2117 5.93936C16.9546 5.69935 16.5515 5.71325 16.3115 5.9704C16.0715 6.22755 16.0854 6.63058 16.3426 6.87058L17.111 7.58779H13.7226C13.3708 7.58779 13.0857 7.87295 13.0857 8.2247C13.0857 8.57645 13.3708 8.8616 13.7226 8.8616H17.111L16.3426 9.57881C16.0854 9.81882 16.0715 10.2218 16.3115 10.479C16.5515 10.7361 16.9546 10.75 17.2117 10.51L19.1614 8.69031C19.2905 8.56986 19.3637 8.40124 19.3637 8.2247C19.3637 8.04816 19.2905 7.87954 19.1614 7.75909L17.2117 5.93936Z"
            fill="#16181D"
          />
        </>
      ) : (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.69203 3.18409C9.69203 4.8543 8.32267 6.20827 6.63348 6.20827C4.94429 6.20827 3.57494 4.8543 3.57494 3.18409C3.57494 1.51388 4.94429 0.159912 6.63348 0.159912C8.32267 0.159912 9.69203 1.51388 9.69203 3.18409ZM8.29203 3.18409C8.29203 4.06623 7.56443 4.80827 6.63348 4.80827C5.70254 4.80827 4.97494 4.06623 4.97494 3.18409C4.97494 2.30196 5.70254 1.55991 6.63348 1.55991C7.56443 1.55991 8.29203 2.30196 8.29203 3.18409Z"
            fill="#16181D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.471069 13.1597C0.471069 10.7978 1.73108 8.10365 6.68536 8.10365C11.6396 8.10365 12.8997 10.7978 12.8997 13.1597C12.8997 13.5355 12.616 13.8401 12.266 13.8401H1.10468C0.754748 13.8401 0.471069 13.5355 0.471069 13.1597ZM10.6426 10.7178C11.09 11.1817 11.3448 11.7766 11.4469 12.4401H1.92386C2.02596 11.7766 2.28078 11.1817 2.72809 10.7178C3.31958 10.1045 4.45721 9.50365 6.68536 9.50365C8.91352 9.50365 10.0511 10.1045 10.6426 10.7178Z"
            fill="#16181D"
          />
          <path
            d="M11.8601 0.597524C11.5255 0.403943 11.0973 0.518297 10.9037 0.85294C10.7101 1.18758 10.8245 1.61579 11.1591 1.80937C11.6349 2.08461 11.9525 2.59732 11.9525 3.18337C11.9525 3.76943 11.6349 4.28214 11.1591 4.55738C10.8245 4.75096 10.7101 5.17917 10.9037 5.51381C11.0973 5.84845 11.5255 5.9628 11.8601 5.76922C12.7507 5.25404 13.3525 4.28939 13.3525 3.18337C13.3525 2.07736 12.7507 1.11271 11.8601 0.597524Z"
            fill="#16181D"
          />
          <path
            d="M16.3474 5.93228C16.0902 5.69227 15.6872 5.70617 15.4472 5.96332C15.2072 6.22047 15.2211 6.6235 15.4782 6.8635L16.2467 7.58071H12.8583C12.5065 7.58071 12.2214 7.86587 12.2214 8.21762C12.2214 8.56937 12.5065 8.85452 12.8583 8.85452H16.2467L15.4782 9.57173C15.2211 9.81174 15.2072 10.2148 15.4472 10.4719C15.6872 10.7291 16.0902 10.743 16.3474 10.503L18.2971 8.68323C18.4261 8.56278 18.4994 8.39416 18.4994 8.21762C18.4994 8.04108 18.4261 7.87246 18.2971 7.75201L16.3474 5.93228Z"
            fill="#16181D"
          />
        </>
      )}
    </svg>
  )
}

export default AllocationsIcon
