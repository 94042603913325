import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const ChecklistIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 20, 20',
  active,
}: SvgIconType) => {
  return (
    <svg width="20" height="20" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      {active ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.666687 5.00842C0.666687 1.97509 2.47502 0.166748 5.50835 0.166748H12.4917C15.525 0.166748 17.3334 1.97509 17.3334 5.00842V11.9917C17.3334 15.0251 15.525 16.8334 12.4917 16.8334H5.50835C2.47502 16.8334 0.666687 15.0251 0.666687 11.9917V5.00842ZM14.2587 5.89185C14.2587 6.23351 13.9837 6.51685 13.6337 6.51685H9.25875C8.91712 6.51685 8.63379 6.23351 8.63379 5.89185C8.63379 5.55018 8.91712 5.26685 9.25875 5.26685H13.6337C13.9837 5.26685 14.2587 5.55018 14.2587 5.89185ZM5.43381 6.95838L7.30881 5.08338C7.55048 4.84172 7.55048 4.44172 7.30881 4.20005C7.05881 3.95838 6.66714 3.95838 6.42548 4.20005L4.99214 5.63338L4.80881 5.45005C4.55881 5.20838 4.16714 5.20838 3.92548 5.45005C3.67548 5.69172 3.67548 6.09172 3.92548 6.33338L4.55048 6.95838C4.66714 7.08338 4.83381 7.14172 4.99214 7.14172C5.15048 7.14172 5.30881 7.08338 5.43381 6.95838ZM14.2587 11.7251C14.2587 12.0668 13.9837 12.3501 13.6337 12.3501H9.25875C8.91712 12.3501 8.63379 12.0668 8.63379 11.7251C8.63379 11.3834 8.91712 11.1001 9.25875 11.1001H13.6337C13.9837 11.1001 14.2587 11.3834 14.2587 11.7251ZM5.43381 12.7916L7.30881 10.9166C7.55048 10.675 7.55048 10.275 7.30881 10.0333C7.05881 9.79163 6.66714 9.79163 6.42548 10.0333L4.99214 11.4666L4.80881 11.2833C4.55881 11.0416 4.16714 11.0416 3.92548 11.2833C3.67548 11.525 3.67548 11.925 3.92548 12.1666L4.55048 12.7916C4.66714 12.9166 4.83381 12.975 4.99214 12.975C5.15048 12.975 5.30881 12.9166 5.43381 12.7916Z"
            fill="#16181D"
          />
        </>
      ) : (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.4917 1.56675H5.50835C4.27011 1.56675 3.44957 1.93043 2.93997 2.44003C2.43037 2.94963 2.06669 3.77017 2.06669 5.00842V11.9917C2.06669 13.23 2.43037 14.0505 2.93997 14.5601C3.44957 15.0697 4.27011 15.4334 5.50835 15.4334H12.4917C13.7299 15.4334 14.5505 15.0697 15.0601 14.5601C15.5697 14.0505 15.9334 13.23 15.9334 11.9917V5.00842C15.9334 3.77017 15.5697 2.94963 15.0601 2.44003C14.5505 1.93043 13.7299 1.56675 12.4917 1.56675ZM5.50835 0.166748H12.4917C15.525 0.166748 17.3334 1.97509 17.3334 5.00842V11.9917C17.3334 15.0251 15.525 16.8334 12.4917 16.8334H5.50835C2.47502 16.8334 0.666687 15.0251 0.666687 11.9917V5.00842C0.666687 1.97509 2.47502 0.166748 5.50835 0.166748ZM14.2587 5.89185C14.2587 6.23351 13.9837 6.51685 13.6337 6.51685H9.25875C8.91712 6.51685 8.63379 6.23351 8.63379 5.89185C8.63379 5.55018 8.91712 5.26685 9.25875 5.26685H13.6337C13.9837 5.26685 14.2587 5.55018 14.2587 5.89185ZM5.43381 6.95838L7.30881 5.08338C7.55048 4.84172 7.55048 4.44172 7.30881 4.20005C7.05881 3.95838 6.66714 3.95838 6.42548 4.20005L4.99214 5.63338L4.80881 5.45005C4.55881 5.20838 4.16714 5.20838 3.92548 5.45005C3.67548 5.69172 3.67548 6.09172 3.92548 6.33338L4.55048 6.95838C4.66714 7.08338 4.83381 7.14172 4.99214 7.14172C5.15048 7.14172 5.30881 7.08338 5.43381 6.95838ZM14.2587 11.7251C14.2587 12.0668 13.9837 12.3501 13.6337 12.3501H9.25875C8.91712 12.3501 8.63379 12.0668 8.63379 11.7251C8.63379 11.3834 8.91712 11.1001 9.25875 11.1001H13.6337C13.9837 11.1001 14.2587 11.3834 14.2587 11.7251ZM5.43381 12.7916L7.30881 10.9166C7.55048 10.675 7.55048 10.275 7.30881 10.0333C7.05881 9.79163 6.66714 9.79163 6.42548 10.0333L4.99214 11.4666L4.80881 11.2833C4.55881 11.0416 4.16714 11.0416 3.92548 11.2833C3.67548 11.525 3.67548 11.925 3.92548 12.1666L4.55048 12.7916C4.66714 12.9166 4.83381 12.975 4.99214 12.975C5.15048 12.975 5.30881 12.9166 5.43381 12.7916Z"
            fill="#16181D"
          />
        </>
      )}
    </svg>
  )
}

export default ChecklistIcon
