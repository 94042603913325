import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const TasksIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 20, 20',
  active,
}: SvgIconType) => {
  return (
    <svg width="20" height="20" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      {active ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.8748 0.674805C2.55521 0.674805 0.674805 2.55521 0.674805 4.8748V13.1252C0.674805 15.4448 2.55521 17.3252 4.8748 17.3252H13.1252C15.4448 17.3252 17.3252 15.4448 17.3252 13.1252V4.8748C17.3252 2.55521 15.4448 0.674805 13.1252 0.674805H4.8748ZM15.8907 4.72373C16.142 4.47241 16.142 4.06493 15.8907 3.81361C15.6394 3.56229 15.2319 3.56229 14.9806 3.81361L9.00014 9.79404L7.61649 8.41039C7.36517 8.15907 6.9577 8.15907 6.70637 8.41039C6.45505 8.66171 6.45505 9.06918 6.70637 9.3205L8.54509 11.1592C8.79641 11.4105 9.20388 11.4105 9.4552 11.1592L15.8907 4.72373Z"
            fill="#16181D"
          />
        </>
      ) : (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.94003 2.94003C3.44963 2.43043 4.27017 2.06675 5.50841 2.06675H11.4409C11.8275 2.06675 12.1409 1.75335 12.1409 1.36675C12.1409 0.980149 11.8275 0.666748 11.4409 0.666748H5.50841C2.47508 0.666748 0.666748 2.47509 0.666748 5.50842V12.4917C0.666748 15.5251 2.47508 17.3334 5.50841 17.3334H12.4917C15.5251 17.3334 17.3334 15.5251 17.3334 12.4917V8.20842C17.3334 7.82182 17.02 7.50842 16.6334 7.50842C16.2468 7.50842 15.9334 7.82182 15.9334 8.20842V12.4917C15.9334 13.73 15.5697 14.5505 15.0601 15.0601C14.5505 15.5697 13.73 15.9334 12.4917 15.9334H5.50841C4.27017 15.9334 3.44963 15.5697 2.94003 15.0601C2.43043 14.5505 2.06675 13.73 2.06675 12.4917V5.50842C2.06675 4.27017 2.43043 3.44963 2.94003 2.94003ZM15.5574 4.72373C15.8087 4.47241 15.8087 4.06493 15.5574 3.81361C15.3061 3.56229 14.8986 3.56229 14.6473 3.81361L8.66683 9.79404L7.28318 8.41039C7.03186 8.15907 6.62438 8.15907 6.37306 8.41039C6.12174 8.66171 6.12174 9.06918 6.37306 9.3205L8.21177 11.1592C8.46309 11.4105 8.87057 11.4105 9.12189 11.1592L15.5574 4.72373Z"
            fill="#1B1C1D"
          />
        </>
      )}
    </svg>
  )
}

export default TasksIcon
