export const ROOM_FILTER = 'ROOM_FILTER'
export const ROOM_LISTING = 'ROOM_LISTING'
export const ROOM_DETAIL = 'ROOM_DETAIL'
export const GET_NOTES = 'GET_NOTES'
export const CLEAR_NOTES = 'CLEAR_NOTES'
export const SET_FILTERS_OPTIONS = 'SET_FILTERS_OPTIONS'
export const SET_STATE = 'SET_STATE'
export const LIST_SET_STATE = 'LIST_SET_STATE'
export const NOTES_SET_STATE = 'NOTES_SET_STATE'
export const UPDATE_STATE_SIDEBAR = 'UPDATE_STATE_SIDEBAR'
export const ROOMS_LAYOUT_SOCKET = 'ROOMS_LAYOUT_SOCKET'
