import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const RoomIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 20, 20',
  active,
}: SvgIconType) => {
  return (
    <svg width="20" height="20" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      {active ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.8472 18.3334H15.4328C16.5745 18.3334 17.5 17.3908 17.5 16.2281V5.43864C17.5 4.27593 16.5745 3.33337 15.4328 3.33337H13.4941C13.4945 3.35133 13.4947 3.36934 13.4947 3.38742V16.8031C13.4947 17.4113 13.2438 17.9521 12.8472 18.3334Z"
            fill="#16181D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.84723 4.24402V15.9784C2.84723 17.0315 3.64873 17.9268 4.73384 18.0858L10.2894 18.9C11.6348 19.0972 12.8472 18.0983 12.8472 16.7927V3.20771C12.8472 1.86708 11.5726 0.859192 10.2009 1.11508L4.6453 2.15138C3.60109 2.34616 2.84723 3.22353 2.84723 4.24402ZM11.0417 8.48252C10.6581 8.48252 10.3472 8.78079 10.3472 9.14872V10.4811C10.3472 10.849 10.6581 11.1473 11.0417 11.1473C11.4252 11.1473 11.7361 10.849 11.7361 10.4811V9.14872C11.7361 8.78079 11.4252 8.48252 11.0417 8.48252Z"
            fill="#16181D"
          />
        </>
      ) : (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0908 0.525164C11.5789 0.247576 13.0176 1.16136 13.367 2.55292L14.9305 2.55287C16.4654 2.55287 17.7527 3.75271 17.7527 5.28469V16.2103C17.7527 17.7423 16.4655 18.9421 14.9305 18.9421L12.3664 18.9422C11.7751 19.3896 11.0015 19.6107 10.2024 19.4936L4.6468 18.6794C3.28561 18.4799 2.24719 17.3475 2.24719 15.9783V4.24393C2.24719 2.91737 3.22418 1.80603 4.53524 1.56147L10.0908 0.525164ZM12.1454 3.20763C12.1454 2.27233 11.3435 1.62482 10.3108 1.81745L4.75528 2.85375C3.97794 2.99875 3.50057 3.52951 3.50057 4.24393V15.9783C3.50057 16.7153 4.01177 17.2825 4.82081 17.4011L10.3764 18.2153C11.3884 18.3636 12.1454 17.7038 12.1454 16.7926V3.20763ZM13.4634 3.86299V16.7926C13.4634 17.2991 13.4012 17.3389 13.2887 17.633L14.9305 17.633C15.8501 17.633 16.509 17.0331 16.509 16.2103V5.28469C16.509 4.46193 15.8502 3.86295 14.9305 3.86294L13.4634 3.86299ZM10.2083 8.48246C9.82477 8.48246 9.51386 8.78072 9.51386 9.14865V10.481C9.51386 10.849 9.82477 11.1472 10.2083 11.1472C10.5918 11.1472 10.9027 10.849 10.9027 10.481V9.14865C10.9027 8.78072 10.5918 8.48246 10.2083 8.48246Z"
            fill="#16181D"
          />
        </>
      )}
    </svg>
  )
}

export default RoomIcon
