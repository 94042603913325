import React from 'react'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const antIcon = <Loading3QuartersOutlined spin />

const SectionSpinner: React.FunctionComponent = () => {
  return (
    <div className="sectionLoader">
      <Spin indicator={antIcon} size="large" spinning />
    </div>
  )
}

export default SectionSpinner
