import React from 'react'
import { SvgIconType } from '../../../Icons/types'

const DashboardIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 20, 20',
  active,
}: SvgIconType) => {
  return (
    <svg width="20" height="20" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      {active ? (
        <>
          <path
            d="M7.62353 14.5883C10.9502 14.5883 13.6471 11.8915 13.6471 8.56478H7.62353L7.62356 2.54124C4.29685 2.54122 1.6 5.23807 1.6 8.56478C1.6 11.8915 4.29682 14.5883 7.62353 14.5883Z"
            fill="#16181D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.02356 3.17404C4.31056 3.47261 2.2 5.77223 2.2 8.56478C2.2 11.5601 4.6282 13.9883 7.62353 13.9883C10.4161 13.9883 12.7157 11.8778 13.0142 9.16478H7.62353C7.4644 9.16478 7.31178 9.10157 7.19926 8.98905C7.08674 8.87652 7.02353 8.72391 7.02353 8.56478L7.02356 3.17404ZM1 8.56478C1 4.9067 3.96548 1.94121 7.62357 1.94124C7.95494 1.94124 8.22357 2.20987 8.22356 2.54124L8.22353 7.96478H13.6471C13.9784 7.96478 14.2471 8.23341 14.2471 8.56478C14.2471 12.2229 11.2816 15.1883 7.62353 15.1883C3.96545 15.1883 1 12.2229 1 8.56478Z"
            fill="#16181D"
          />
          <path
            d="M9.79199 1.87795V6.33768H13.9332V6.01913C13.9332 3.73202 12.0791 1.87795 9.79199 1.87795Z"
            fill="#16181D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.19199 1.87795C9.19199 1.54658 9.46062 1.27795 9.79199 1.27795C12.4105 1.27795 14.5332 3.40065 14.5332 6.01913V6.33768C14.5332 6.66905 14.2645 6.93768 13.9332 6.93768H9.79199C9.46062 6.93768 9.19199 6.66905 9.19199 6.33768V1.87795ZM10.392 2.52857V5.73768H13.3221C13.1945 4.11399 11.9716 2.79817 10.392 2.52857Z"
            fill="#16181D"
          />
        </>
      ) : (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.19258 1.87795C9.19258 1.54658 9.46121 1.27795 9.79258 1.27795C12.4111 1.27795 14.5338 3.40065 14.5338 6.01913V6.33768C14.5338 6.66905 14.2651 6.93768 13.9338 6.93768H9.79258C9.46121 6.93768 9.19258 6.66905 9.19258 6.33768V1.87795ZM10.3926 2.52857V5.73768H13.3227C13.1951 4.11399 11.9722 2.79817 10.3926 2.52857ZM7.02356 3.17446C4.31055 3.47302 2.2 5.77264 2.2 8.56519C2.2 11.5605 4.6282 13.9887 7.62353 13.9887C10.4161 13.9887 12.7157 11.8782 13.0142 9.16519H7.62353C7.4644 9.16519 7.31178 9.10198 7.19926 8.98946C7.08674 8.87693 7.02353 8.72432 7.02353 8.56519L7.02356 3.17446ZM1 8.56519C1 4.90711 3.96548 1.94163 7.62357 1.94165C7.95494 1.94165 8.22357 2.21028 8.22356 2.54165L8.22353 7.96519H13.6471C13.9784 7.96519 14.2471 8.23382 14.2471 8.56519C14.2471 12.2233 11.2816 15.1887 7.62353 15.1887C3.96545 15.1887 1 12.2233 1 8.56519Z"
            fill="#16181D"
          />
        </>
      )}
    </svg>
  )
}

export default DashboardIcon
